/*
 * @Description: 文件描述
 */

const store = {
  namespace: 'report',
  state: {
    currentNode: {}
  },
  mutations: {
    SET_CURRENT_NODE(state, payload) {
      state.currentNode = payload
    }
  }
}

export default store
