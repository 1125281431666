import request from '@/utils/request'
const Api = {
  resetPassword(payload) {
    return request({
      method: 'PUT',
      url: '/api/userinfo/updateUserInfo',
      data: {
        ...payload
      }
    })
  }
  /**
postTemo(payload) {
  return request({
    method: 'POST',
    url: '/api/users',
    data: { ...payload }
  })
}
 */
}
export default Api
