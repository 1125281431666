/**
 * 表单验证方法集合
 */
// 简单密码
const REG_EASY_PWD = /[\S\s]+/
// 复杂密码
const REG_COMPLEX_PWD = /^(?=.*\d)(?=.*[a-zA-Z])[\da-zA-Z]{8,16}$/
// 手机
const REG_PHONE = /^((\+?86)|(\(\+86\)))?((((13[^4]{1})|(14[5-9]{1})|147|(15[^4]{1})|166|(17\d{1})|(18\d{1})|(19[12589]{1}))\d{8})|((134[^9]{1}|1410|1440)\d{7}))$/
// 邮箱
const REG_EMAIL = /[\w\-_]+@[a-zA-Z0-9\\-]{0,61}\.[a-zA-Z]{2,6}$/
// 身份证
const REG_IDCARD = /\d{17}([0-9xX])$/
// 用户名
const REG_USERNAME = /^(?=.*\d)(?=.*[a-zA-Z])[\da-zA-Z~!@#$%^&*]{8,15}$/
// 真实姓名
const REG_REALNAME = /(^[\u4E00-\u9FA5]{2,7}$)|(^[a-zA-Z\s]+$)/

// 校验并且按消息提示
function requiredAndValidatorByMsg(rule, value, callback, reg, nullMsg, formatMsg) {
  if ((value && value.length > 0)) {
    if (!reg.test(value)) {
      callback(new Error(formatMsg))
    } else {
      callback()
    }
  } else {
    callback(new Error(nullMsg))
  }
}

// 必填且校验
function requiredAndValidator(rule, value, callback, reg, msg) {
  if ((value && value.length > 0)) {
    if (!reg.test(value)) {
      callback(new Error(msg + '格式错误'))
    } else {
      callback()
    }
  } else {
    callback(new Error('请输入' + msg))
  }
}

function validatorNullable(rule, value, callback, reg, msg) {
  if ((value && value.length > 0)) {
    if (!reg.test(value)) {
      callback(new Error(msg + '格式错误'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}
export default {
  // 必须存在且相同
  requiredAndIsSame: function (sameTo, nullMsg, unSameMsg) {
    return {
      rules: [{
        trigger: 'blur',
        required: true,
        validator: (rule, value, callback) => {
          if (value && value.length > 0) {
            if (value !== sameTo) {
              callback(new Error(unSameMsg))
            } else {
              callback()
            }
          } else {
            callback(new Error(nullMsg))
          }
        }
      }],
      help: '再次输入'
    }
  },
  // 自定义复杂密码
  complexPwdFun: function (label) {
    return {
      rules: [{
        trigger: 'blur',
        required: true,
        validator: (rule, value, callback) => {
          if (value && value.length > 0) {
            if (value.length < 6 || value.length > 16) {
              callback(new Error(label + '长度应为8-16位'))
            } else {
              requiredAndValidatorByMsg(rule, value, callback, REG_COMPLEX_PWD, '请输入密码', '请输入8-16位密码,必须包含字母和数字')
            }
          } else {
            callback(new Error('请输入' + label))
          }
        }
      }],
      help: '简单密码'
    }
  },

  // 自定义label简单密码
  easypwdFun: function (label) {
    return {
      rules: [{
        required: true,
        validator: (rule, value, callback) => {
          if (value && value.length > 0) {
            if (value.length < 4 || value.length > 20) {
              callback(new Error(label + '长度应为4-20位'))
            } else {
              requiredAndValidatorByMsg(rule, value, callback, REG_EASY_PWD, '请输入密码', '请输入4-20位密码')
            }
          } else {
            callback(new Error('请输入' + label))
          }
        }
      }],
      help: '简单密码'
    }
  },
  // 简单密码验证
  easypwd: {
    rules: [{
      required: true,
      validator: (rule, value, callback) => {
        if (value && value.length > 0) {
          if (value.length < 4 || value.length > 20) {
            callback(new Error('密码长度应为4-20位'))
          } else {
            requiredAndValidator(rule, value, callback, REG_EASY_PWD, '密码')
          }
        } else {
          callback(new Error('请输入密码'))
        }
      }
    }],
    help: '简单密码'
  },
  // 复杂密码验证
  complexpwd: {
    rules: [{
      required: true,
      validator: (rule, value, callback) => {
        if (value && value.length > 0) {
          requiredAndValidator(rule, value, callback, REG_COMPLEX_PWD, '密码')
        } else {
          callback(new Error('请输入密码'))
        }
      }
    }],
    help: '复杂密码'
  },
  // 可空手机号验证
  phoneNullable: {
    rules: [{
      validator: (rule, value, callback) => {
        validatorNullable(rule, value, callback, REG_PHONE, '手机号')
      }
    }],
    help: '11位数字'
  },
  // 不可空手机号验证
  phone: {
    rules: [{
      required: true,
      validator: (rule, value, callback) => {
        requiredAndValidator(rule, value, callback, REG_PHONE, '手机号')
      }
    }],
    help: '11位数字'
  },
  // 可空邮箱地址验证
  emailNullable: {
    rules: [{
      validator: (rule, value, callback) => {
        validatorNullable(rule, value, callback, REG_EMAIL, '邮箱')
      }
    }],
    help: '邮箱地址'
  },
  // 不可空邮箱地址验证
  email: {
    rules: [{
      required: true,
      validator: (rule, value, callback) => {
        requiredAndValidator(rule, value, callback, REG_EMAIL, '邮箱')
      }
    }],
    help: '邮箱地址'
  },
  // 不可空身份证号验证
  idcard: {
    rules: [{
      required: true,
      validator: (rule, value, callback) => {
        requiredAndValidator(rule, value, callback, REG_IDCARD, '身份证号')
      }
    }],
    help: '身份证号'
  },
  // 不可空用户名验证
  username: {
    rules: [{
      required: true,
      validator: (rule, value, callback) => {
        if (value && value.length > 0) {
          requiredAndValidatorByMsg(rule, value, callback, REG_USERNAME, '请输入用户名', '请输入正确的用户名')
        } else {
          callback(new Error('请输入用户名'))
        }
      }
    }],
    help: '字母数字组合'
  },
  // 不可空真实姓名
  realname: {
    rules: [{
      required: true,
      validator: (rule, value, callback) => {
        requiredAndValidator(rule, value, callback, REG_REALNAME, '姓名')
      }
    }],
    help: '汉字，字母，数字组合'
  }
}

export { requiredAndValidatorByMsg }
