import router from './router'
import store from './store'
import storage from 'store'
import NProgress from 'nprogress' // progress bar
import '@/components/NProgress/nprogress.less' // progress bar custom style
import { notification } from 'ant-design-vue'
import { setDocumentTitle, domTitle } from '@/utils/domUtil'
import { ACCESS_TOKEN, CURRENT_USER } from '@/store/mutation-types'
import { i18nRender } from '@/locales'
import { constantRouterMap } from '@/config/router.config'
NProgress.configure({ showSpinner: false }) // NProgress Configuration
const allowList = [] // no redirect allowList 'login', 'register', 'registerResult'
const loginRoutePath = '/user/login'
const defaultRoutePath = '/dashboard'

function treeToList(tree, list) {
  return tree.forEach(item => {
    var k = { ...item }
    delete k.childVms
    list.push(k)
    if (item.childVms && item.childVms.length) {
      treeToList(item.childVms, list)
    }
  })
}

function getConstantRouter(routers, pathList) {
  routers.forEach((item) => {
    pathList.push(item.path)
    if (item.children && item.children.length > 0) {
      getConstantRouter(item.children, pathList)
    }
  })
}
var pathList = []
getConstantRouter(constantRouterMap, pathList)

router.beforeEach((to, from, next) => {
  // console.log('object')
  next()
})

router.beforeEach((to, from, next) => {
  NProgress.start() // start progress bar
  to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(`${i18nRender(to.meta.title)} - ${domTitle}`))
  /* has token */
  if (storage.get(ACCESS_TOKEN)) {
    // 装载用户权限
    store.dispatch('GetUserAllPermission')
    if (!storage.get(CURRENT_USER)) {
      store.dispatch('GetInfo')
    }
    if (to.path === loginRoutePath) {
      next({ path: defaultRoutePath })
      NProgress.done()
    } else {
      if (store.getters.roles.length === 0) {
        // 获取权限，原有代码限制，尽量少折腾
        store.dispatch('fetchBtnAuthCodeList').then(() => {
          return store.dispatch('GerPermissionMenus')
        }).then(res => {
            var roles = []
            treeToList(res, roles)
            store.dispatch('GenerateRoutes', { roles }).then(() => {
              // 根据roles权限生成可访问的路由表
              // 动态添加可访问路由表
              router.addRoutes(store.getters.addRouters)
              // 请求带有 redirect 重定向时，登录自动重定向到该地址
              const redirect = decodeURIComponent(from.query.redirect || to.path)
              if (to.path === redirect) {
                next({ ...to, replace: true })
              } else {
                // 跳转到目的路由
                next({ path: redirect })
              }
            })
          })
          .catch(() => {
            notification.error({
              message: '错误',
              description: '请求用户信息失败，请重试'
            })
            // 失败时，获取用户信息失败时，调用登出，来清空历史保留信息
            store.dispatch('Logout').then(() => {
              next({ path: loginRoutePath, query: { redirect: to.fullPath } })
            })
          })
      } else {
        next()
        // debugger
      }
    }
  } else {
    if (allowList.includes(to.name) || pathList.includes(to.path)) {
      // 在免登录名单，直接进入
      next()
    } else {
      next({ path: loginRoutePath, query: { redirect: to.fullPath } })
      NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
