import request from '@/utils/request'

const userApi = {
  Login: '/api/login',
  GetValidCodeByPhone: '/api/userinfo/getValidCodeByPhone',
  GetValidCodeByEmail: '/api/userinfo/getValidCodeByEmail',
  LoginWithSms: '/api/userinfo/loginWithSms',
  LoginWithEmail: '/api/userinfo/loginWithEmail'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login(parameter) {
  return request({
    url: userApi.Login,
    method: 'post',
    data: parameter
  })
}

export function getValidCodeByPhone(parameter) {
  return request({
    url: `${userApi.GetValidCodeByPhone}/${parameter.phone}`,
    method: 'GET'
  })
}
export function getValidCodeByEmail(parameter) {
  return request({
    url: `${userApi.GetValidCodeByEmail}/${parameter.email}`,
    method: 'GET'
  })
}
export function loginWithSms(parameter) {
  return request({
    url: userApi.LoginWithSms,
    method: 'POST',
    data: { ...parameter }
  })
}
export function loginWithEmail(parameter) {
  return request({
    url: userApi.LoginWithEmail,
    method: 'POST',
    data: { ...parameter }
  })
}
