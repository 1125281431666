/*
 * @Description: 文件描述
 */

const RESPONSE_ERROR_CODE = {
  998: '系统错误', // 规定998为后台返回的业务级别处理错误 已弃用
  999: '异步数据校验错误', // 规定code===999为异步数据校验错误
  900: '系统错误', // 规定code===999为业务异常
  404: '请求的资源不存在!', // 有业务意义404  轻提示 返回给前台
  403: '无操作权限!', // 无操作权限!
  401: '权限错误' // 401权限错误或者无权限
}

export {
  RESPONSE_ERROR_CODE
}
export default RESPONSE_ERROR_CODE
