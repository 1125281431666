// eslint-disable-next-line import/extensions
import db from './util.db'

const dataBus = {
  push(key, value) {
    db.set(`sys.databus.${key}`, value).write()
  },
  get(key) {
    return db.get(`sys.databus.${key}`).value()
  },
  pop(key) {
    const res = db.get(`sys.databus.${key}`).value()
    db.unset(`sys.databus.${key}`).write()
    return res
  },
  remove(key) {
    db.unset(`sys.databus.${key}`).write()
  }
}
export default dataBus
