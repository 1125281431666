/*
 * @Description: 文件描述
 */
const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view'),
}
export default [
  // 报告工单管理
  {
    sort: 20,
    path: '/report',
    component: RouteView,
    redirect: '/report/report-list',
    name: 'report',
    meta: { title: '报告工单管理', icon: 'setting', permission: ['ORGANIZATION_WORKORDERPROCESS'] },
    children: [
      // 报告列表
      {
        path: '/report/report-list',
        name: 'reportList',
        component: () => import('@/views/report/report-list'),
        meta: { title: '报告工单管理', permission: ['ORGANIZATION_WORKORDERPROCESS_CHILD'] },
      },
      // 楼栋详情
      {
        path: '/report/project-detail/:id',
        name: 'projectDetail',
        hidden: true,
        component: () => import('@/views/report/project-detail'),
        meta: { title: '项目详情', permission: ['ORGANIZATION_WORKORDERPROCESS_CHILD'] },
      },
      // 请求分析
      {
        path: '/report/submit-application/:id',
        name: 'submitApplication',
        hidden: true,
        component: () => import('@/views/report/submit-application'),
        meta: { title: '请求分析', permission: ['ORGANIZATION_WORKORDERPROCESS_CHILD'] },
      },
      // 分析详情
      {
        path: '/report/report-analysis-detail/:id',
        name: 'analysisDetail',
        hidden: true,
        component: () => import('@/views/report/report-analysis-detail'),
        meta: { title: '分析详情', permission: ['ORGANIZATION_WORKORDERPROCESS_CHILD'] },
      },
      // 查看报告
      {
        path: '/report/view-report/:id',
        name: 'viewReport',
        hidden: true,
        component: () => import('@/views/report/view-report'),
        meta: { title: '查看报告', permission: ['ORGANIZATION_WORKORDERPROCESS_CHILD'] },
      },
    ],
  },
]
