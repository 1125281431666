
/*
 * @Description: 文件描述
 */
const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view'),
}
export default [
  {
    sort: 40,
    path: '/finance',
    component: RouteView,
    redirect: '/finance/order-list',
    name: 'finance',
    meta: { title: '财务管理', icon: 'setting', permission: ['ORGANIZATION_FINANCE'] },
    children: [
      {
        path: '/finance/order-list',
        name: 'orderList',
        component: () => import('@/views/finance'),
        meta: { title: '财务管理', permission: ['ORGANIZATION_FINANCE'] },
      },
      {
        path: '/finance/deposit-money',
        name: 'depositMoney',
        hidden: true,
        component: () => import('@/views/finance/depositMoney'),
        meta: { title: '充值', permission: ['ORGANIZATION_FINANCE'] },
      },
      {
        path: '/finance/resubmit-order/:id',
        name: 'resubmitOrder',
        hidden: true,
        component: () => import('@/views/finance/depositMoney'),
        meta: { title: '重新提交', permission: ['ORGANIZATION_FINANCE'] },
      }
    ]
  },
]
