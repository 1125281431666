/*
 * @Description: 文件描述
 */
import store from '@/store/index'

function checkAuth(el, binding) {
  const { value, arg } = binding
  const authList = store.state.permission?.btnAuthCodeList || [] // 拿到权限列表
  let hasAuth = true

  if (!['boolean', 'string', 'undefined'].includes(typeof value)) { // 传递的value只能是字符串或者布尔类型
    throw new Error('v-auth的绑定值应该是code码或者一个布尔值')
  }

  if (arg) { // 如果是通过参数形式传入
    hasAuth = authList.includes(arg)
  } else { // 没有参数判断表达式value
    hasAuth = value === true || authList.includes(value)
  }

  if (!hasAuth) {
    el.parentNode && el.parentNode.removeChild(el)
  }
}

export default {
  inserted(el, binding) {
    checkAuth(el, binding)
  },
  update(el, binding) {
    checkAuth(el, binding)
  }
}
