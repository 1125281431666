import api from './api'

export default {
  namespaced: true,
  namespace: 'ResetPasswrodModule',
  state: {
  },
  mutations: {
  },
  actions: {
    async resetPassword({ dispatch, commit }, payload) {
      const res = await api.resetPassword(payload)
      return res
    }
  }
}
