// 日期处理所有函数放到这里

import moment from 'moment'

// 默认格式化函数
// 注意通用函数，在没有结果的时候不需要return 特殊值
const formatTime = (value, formatStr = 'YYYY-MM-DD') => {
  if (!value || !moment(value).isValid() || moment(value).isBefore(moment('1970-01-01'))) {
    return ''
  } else {
    return moment(value).format(formatStr)
  }
}

// 生成createTimeFrom和createTimeTo计算属性
// form, createTimeFrom, createTimeTo 都是写死的
function mapFormStartAndEnd(propKey = 'startAndEnd') {
  return {
    [propKey]: {
      get() {
        const { createTimeFrom, createTimeTo } = this.form
        return [createTimeFrom, createTimeTo]
      },
      set([_start, _end]) {
        this.form.createTimeFrom = _start
        this.form.createTimeTo = _end
      }
    }
  }
}

export { formatTime, mapFormStartAndEnd }
export default formatTime
