// eslint-disable-next-line
import { UserLayout, BasicLayout, BlankLayout } from '@/layouts'

/**
 * 自动注入模块内路由配置
 */
var authLoadRouters = []
try {
  //  eslint-disable-next-line
  const files = require.context('../views', true, /router.config.js$/)
  files.keys().forEach(key => {
    if (files(key).default) {
      authLoadRouters = [...authLoadRouters, ...files(key).default]
    }
  })
  // 路由排序
  authLoadRouters = authLoadRouters.sort((a, b) => {
    return a.sort - b.sort
  })
} catch (err) {
  console.error('路由加载出错', err)
}
const RouteView = {
  name: 'RouteView',
  render: h => h('router-view')
}
// console.info('authLoadRoutersauthLoadRouters', authLoadRouters)
export const asyncRouterMap = [
  {
    sort: 4,
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: '首页', permission: ['ALWAYS'] },
    redirect: '/dashboard',
    children: [
      ...authLoadRouters,
      {
        path: '/exception',
        name: 'exception',
        component: RouteView,
        hidden: true,
        redirect: '/403',
        meta: { title: 'menu.exception', icon: 'warning', permission: ['ALWAYS'] },
        children: [
          {
            path: '/403',
            name: 'Exception403',
            component: () => import(/* webpackChunkName: "fail" */ '@/views/core/Exception/403'),
            meta: { title: 'menu.exception.not-permission', permission: ['ALWAYS'] }
          },
          {
            path: '/404',
            name: 'Exception404',
            component: () => import(/* webpackChunkName: "fail" */ '@/views/core/Exception/404'),
            meta: { title: 'menu.exception.not-find', permission: ['ALWAYS'] }
          },
          {
            path: '/500',
            name: 'Exception500',
            component: () => import(/* webpackChunkName: "fail" */ '@/views/core/Exception/500'),
            meta: { title: 'menu.exception.server-error', permission: ['ALWAYS'] }
          }
        ]
      }
    ]
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]
// console.info('asyncRouterMapasyncRouterMapasyncRouterMapasyncRouterMap', asyncRouterMap)
/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: '/user/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/core/Login')
      },
      {
        path: '/user/retrievePassword',
        name: 'retrievePassword',
        component: () => import(/* webpackChunkName: "user" */ '@/views/core/RetrievePassword')
      },
      {
        path: '/user/userRegister',
        name: 'userRegister',
        component: () => import(/* webpackChunkName: "user" */ '@/views/core/UserRegister')
      },
      {
        path: '/404',
        name: 'GlobalException404',
        component: () => import(/* webpackChunkName: "fail" */ '@/views/core/Exception/404'),
        meta: { title: 'menu.exception.not-find', permission: ['ALWAYS'] }
      }
    ]
  }
]
