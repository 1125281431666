export default [
  {
    sort: 0,
    path: '/dashboard',
    name: 'sample',
    component: () => import('@/views/content/DashBoard'),
    hidden: true,
    meta: {
      title: '检测机构管理系统',
      keepAlive: true,
      hidden: true,
      permission: ['ALWAYS'],
    },
  },
]
