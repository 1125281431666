const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view'),
}
export default [
  {
    sort: 30,
    path: '/equipment',
    component: RouteView,
    redirect: '/equipment/list',
    name: 'equipment',
    meta: { title: '设备管理', icon: 'setting', permission: ['ORGANIZATION_DEVICE'] },
    children: [
      {
        path: '/equipment/list',
        name: 'equipmentList',
        component: () => import('@/views/equipment/list'),
        meta: { title: '设备列表', permission: ['ORGANIZATION_DEVICE_CHILD'] },
      },
    ],
  },
]
