<!--
 * @Description: 角色选择器
-->

<template>
  <a-select :value="value" mode="multiple" @change="handleChange" placeholder="请选择角色">
    <a-select-option v-for="option in options" :key="option.value">{{ option.label }}</a-select-option>
  </a-select>
</template>
<script>
import Api from '@/views/core/Role/mod/api'

export default {
  props: {
    value: {
      type: [Number, Array],
      default: undefined
    }
  },
  data() {
    return {
      options: []
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      Api.getRolesByPage({ pagination: { size: 1000, page: 0 } }).then((res) => {
        this.options = res?.content?.map((item) => ({
          label: item.name,
          value: item.id
        }))
      })
    },
    handleChange(newVal) {
      this.$emit('input', [...newVal])
    }
  }
}
</script>
