import { Cascader } from 'ant-design-vue'
import request from '@/utils/request'

export default {
    name: 'DistrictCascader',
    props: Object.assign({
        value: {
            default: ''
        },
        maxLevel: {
            default: 5
        },
        placeholder: {
            default: '请选择地区'
        }
    }),
    watch: {
      value: {
        immediate: true,
        handler(newVal) {
          this.loadTreeData(null, newVal)
        }
      }
    },
    data() {
        return {
            options: [],
            fieldNames: { label: 'name', value: 'code', children: 'children' },
            selectedOptions: [],
            defaultValue: this.getParentCodes(this.value)
        }
    },
    methods: {
        getParentCodes(districtCode) {
            let parentCodes = null

            if (districtCode !== null && districtCode !== '') {
                parentCodes = []
                const provinceCode = districtCode.substring(0, 2) + '0000000000'
                const cityCode = districtCode.substring(0, 4) + '00000000'
                const countryCode = districtCode.substring(0, 6) + '000000'
                const townCode = districtCode.substring(0, 9) + '000'

                if (provinceCode !== districtCode) {
                    parentCodes.push(provinceCode)
                }

                if (!cityCode !== districtCode) {
                    parentCodes.push(cityCode)
                }

                if (!countryCode !== districtCode) {
                    parentCodes.push(countryCode)
                }

                if (!townCode !== districtCode) {
                    parentCodes.push(townCode)
                }

                parentCodes.push(districtCode)
            }

            return parentCodes
        },
        async loadTreeData(targetOption, selectedCode) {
            let parentCode = ''
            if (targetOption !== null) {
                parentCode = targetOption.code
            }
            // 发送请求
            let url = `/api/location/district/getDistricts?parentCode=${parentCode}&maxLevel=${this.maxLevel}`
            if (selectedCode) {
                url += `&selectedCode=${selectedCode}`
            }
            const list = await request({
                method: 'GET',
                url: url
            })

            // 如果targetOption不为空，就说明是加载下级地区
            if (targetOption !== null) {
                targetOption.children = list
            } else {
                this.options = list
            }
        },
        /**
         * 加载下级地区
         */
        async onLoadData(selectedOptions) {
            // 或得当前地区
            const targetOption = selectedOptions[selectedOptions.length - 1]
            // 如果当前地区的层级未大于最大可加载的层级，就可以请求加载下级地区的数据
            if (targetOption.level < this.maxLevel) {
                targetOption.loading = true
                await this.loadTreeData(targetOption, null)
                targetOption.loading = false
            }
        },
        onChange(val, selectedOptions) {
            // 返回已选择的地区数据
            this.$emit('input', val[val.length - 1])
            this.$emit('change', selectedOptions[selectedOptions.length - 1])
        }
    },
    render() {
        return (
            <div>
                <Cascader
                    style="width: 100%"
                    placeholder={this.placeholder}
                    defaultValue={this.defaultValue}
                    fieldNames={this.fieldNames}
                    options={this.options}
                    loadData={this.onLoadData}
                    changeOnSelect={true}
                    onChange={this.onChange}>
                </Cascader>
            </div>
        )
    }
}
