import axios from 'axios'
import store from '@/store'
import storage from 'store'
import router from '@/router'
import { notification, message } from 'ant-design-vue'
import { VueAxios } from './axios'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { RESPONSE_ERROR_CODE } from '@/const/responseErrorCode'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  withCredentials: true,
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 20 * 1000, // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    // 从 localstorage 获取 token
    const token = storage.get(ACCESS_TOKEN)
    // 规定998为后台返回的业务级别处理错误 已弃用
    if (error.response.status === 998) {
      // 弹出错误
      notification.error({
        message: '系统错误',
        description: data,
      })
    }

    if (error.response.status === 404) {
      // 有业务意义404  轻提示 返回给前台
      message.warning('请求的资源不存在!')
      return Promise.resolve(data)
    }
    if (error.response.status === 403) {
      // 轻提示
      message.error('无操作权限!')
      // 跳转403
      return Promise.resolve(data)
    }
    if (error.response.status === 400) {
      if (data.code === 999) {
        // 规定code===999为异步数据校验错误
        // 轻提示 返回给前台
        message.error(data.msg)
      } else if (data.code === 900) {
        // 规定code===999为业务异常
        notification.error({
          message: '系统错误',
          description: data.msg,
        })
      }
      return Promise.resolve(data)
    }
    if (error.response.status === 401) {
      const msg = error.response.data?.message || error.response.data
      message.error(msg)
      if (token) {
        store.dispatch('Logout').then(() => {
          if (router.currentRoute.path === '/user/login') {
            clearTimeout(window.logoutTimer)
            return
          }
          setTimeout(() => {
            window.location.reload()
          }, 100)
        })
      }
    }
  }
  return Promise.reject(error)
}

const requestInterceptor = (config) => {
  config.headers = { ...config.headers }
  // 装入token
  // const token = storage.get(ACCESS_TOKEN)
  // if (token) {
  //   config.headers['authorization'] = token
  // }
  // GET方法缓存问题
  if (config.method === 'get' || config.method === 'GET') {
    if (!config.params) {
      config.params = {}
    }
    config.params.t = new Date().getTime()
  }
  // Body类型为FormData时进行参数处理
  if (config.formData) {
    var fData = new FormData()
    var keys = Object.keys(config.formData)
    keys.forEach((item) => {
      fData.append(item, config.formData[item])
    })
    config.data = fData
    config.headers = { ...config.headers, 'Content-Type': 'multipart/form-data;' }
  }
  return config
}

// request interceptor
request.interceptors.request.use(requestInterceptor, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  if (response.status === 200) {
    return response.data
  }
}, errorHandler)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request)
  },
}

// 在创建一个实例，原有的错误处理是有问题的

const customHandler = (error) => {
  const token = storage.get(ACCESS_TOKEN)
  const { status, data } = error.response || {}
  const errMsg = data?.message || data?.msg || data

  if ([404, 403].includes(status)) {
    message.error(RESPONSE_ERROR_CODE[status])
  }

  if (status === 401 || (status === 400 && data.code !== 900)) {
    message.error(errMsg)
  }

  if (status === 400 && data.code === 900) {
    notification.error({
      message: '系统错误',
      description: data.msg,
    })
  }

  if (status === 401 && token) {
    store.dispatch('Logout').then(() => {
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    })
  }

  return Promise.reject(error)
}

const requestCustom = axios.create({
  // API 请求的默认前缀
  withCredentials: true,
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 20 * 1000, // 请求超时时间
})

// request interceptor
requestCustom.interceptors.request.use(requestInterceptor, customHandler)

// response interceptor
requestCustom.interceptors.response.use((response) => {
  if (response.status === 200) {
    return response.data
  }
}, customHandler)

export default request

export { installer as VueAxios, request as axios, requestCustom }
