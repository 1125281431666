var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentUser && _vm.currentUser.username
    ? _c(
        "a-dropdown",
        {
          attrs: { placement: "bottomRight" },
          scopedSlots: _vm._u(
            [
              {
                key: "overlay",
                fn: function () {
                  return [
                    _c(
                      "a-menu",
                      {
                        staticClass: "ant-pro-drop-down menu",
                        attrs: { "selected-keys": [] },
                      },
                      [
                        _vm.menu
                          ? _c(
                              "a-menu-item",
                              {
                                key: "settings",
                                on: { click: _vm.handleToSettings },
                              },
                              [
                                _c("a-icon", { attrs: { type: "setting" } }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("menu.account.settings")) +
                                    " "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.menu ? _c("a-menu-divider") : _vm._e(),
                        _c(
                          "a-menu-item",
                          { key: "logout", on: { click: _vm.handleLogout } },
                          [
                            _c("a-icon", { attrs: { type: "logout" } }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("menu.account.logout")) + " "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            3164408773
          ),
        },
        [
          _c(
            "span",
            { staticClass: "ant-pro-account-avatar" },
            [
              _c("a-avatar", {
                staticClass: "antd-pro-global-header-index-avatar",
                attrs: { size: "small", src: _vm.avatar },
              }),
              _c("span", [_vm._v(_vm._s(_vm.currentUser.username))]),
            ],
            1
          ),
        ]
      )
    : _c(
        "span",
        [
          _c("a-spin", {
            style: { marginLeft: 8, marginRight: 8 },
            attrs: { size: "small" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }