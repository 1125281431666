import request from '@/utils/request'
const Api = {
  verifyEmailCodeByCurrentUser(payload) {
    return request({
      method: 'POST',
      url: `/api/userinfo/verifyEmailCodeByCurrentUser/${payload}`
    })
  },
  sendEmailCodeByCurrentUser() {
    return request({
      method: 'POST',
      url: '/api/userinfo/sendEmailCodeByCurrentUser'
    })
  },
  verifySmsCodeByCurrentUser(payload) {
    return request({
      method: 'POST',
      url: `/api/userinfo/verifySmsCodeByCurrentUser/${payload}`
    })
  },
  sendSmsCodeByCurrentUser() {
    return request({
      method: 'POST',
      url: '/api/userinfo/sendSmsCodeByCurrentUser'
    })
  },
  changePassword(payload) {
    return request({
      method: 'PUT',
      url: '/api/userinfo/changePassword',
      data: {
        ...payload
      }
    })
  },
  getUserInfo(payload) {
    return request({
      method: 'GET',
      url: '/api/userinfo'
    })
  },
  updateUserBaseInfo(payload) {
    return request({
      method: 'PUT',
      url: '/api/userinfo/updateUserBaseInfo',
      data: {
        ...payload
      }
    })
  },
  bindNewPhone(payload) {
    return request({
      method: 'PUT',
      url: `/api/userinfo/bindNewPhone/${payload.key}/${payload.code}`
    })
  },
  bindNewEmail(payload) {
    return request({
      method: 'PUT',
      url: `/api/userinfo/bindNewEmail/${payload.key}/${payload.code}`
    })
  },
  changeUserName(payload) {
    return request({
      method: 'PUT',
      url: `/api/userinfo/changeUserName/${payload.username}`
    })
  }

  /**
postTemo(payload) {
  return request({
    method: 'POST',
    url: '/api/users',
    data: { ...payload }
  })
}
 */
}
export default Api
