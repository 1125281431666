<!--
 * @Description: 文件描述
-->
<template>
  <div class="content_layout_wrapper">
    <!-- 筛选区域插槽 -->
    <a-card class="filter_area_wrapper">
      <slot name="top"></slot>
    </a-card>

    <div class="mt-10"></div>

    <!-- 默认插槽 -->
    <a-card class="table_area_wrapper">
      <slot></slot>
    </a-card>
  </div>
</template>

<script>
export default {
  name: 'ContentLayout',
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.content_layout_wrapper {
  height: calc(100vh - 185px);
  display: flex;
  flex-direction: column;

  .table_area_wrapper {
    flex: 1;

    ::v-deep .ant-card-body {
      height: 100%;
      flex-direction: column;
      display: flex;

      > div:first-child:not(.btn_wrapper) {
        flex: 1;
        display: flex;
        flex-direction: column;
        .custom_vxe_table_wrapper {
          flex: 1;
        }
      }
    }
  }
}
</style>
