import api from './api'

export default {
  namespaced: true,
  namespace: 'userManage',
  state: {
    userPage: {},
    permissionList: []
  },
  mutations: {
    SAVE_USERPAGE: (state, userPage) => {
      console.info('userPage', userPage)
      state.userPage = { data: userPage.content, pagination: { pageSize: userPage.pageable.pageSize, pageNo: userPage.pageable.pageNumber + 1, total: userPage.totalElements } }
    }
  },
  actions: {
    async addTags({ dispatch, commit }, payload) {
      const res = await api.addTags(payload)
      return res
    },
    async updateUserRbacInfo({ dispatch, commit }, payload) {
      const res = await api.updateUserRbacInfo(payload)
      return res
    },
    async getAllAuthUsers({ dispatch, commit }, payload) {
      const res = await api.getAllAuthUsers(payload)
      // if (res.code === 1000) {
      commit('SAVE_USERPAGE', res)
      // }
      return res
    },
    async getAllFrontAuthUsers({ dispatch, commit }, payload) {
      const res = await api.getAllFrontAuthUsers(payload)
      // if (res.code === 1000) {
      commit('SAVE_USERPAGE', res)
      // }
      return res
    },
    async createUser({ dispatch, commit }, payload) {
      const res = await api.createUser(payload)
      return res
    },
    async updateUser({ dispatch, commit }, payload) {
      const res = await api.updateUser(payload)
      return res
    },
    async deleteUser({ dispatch, commit }, payload) {
      const res = await api.deleteUser(payload)
      return res
    },
    async resetUserPassword({ dispatch, commit }, payload) {
      const res = await api.resetUserPassword(payload)
      return res
    },
    async changeStatus({ dispatch, commit }, payload) {
      const res = await api.changeStatus(payload)
      return res
    },
    async assignPermissions({ dispatch, commit }, payload) {
      const res = await api.assignPermissions(payload)
      return res
    },
    async assignRole({ dispatch, commit }, payload) {
      const res = await api.assignRole(payload)
      return res
    }

  }
}
