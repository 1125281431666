<!--
 * @Description: 筛选区域布局
-->

<script>
const noop = () => {}

export default {
  functional: true,
  render(h, ctx) {
    const { reset, search } = ctx.listeners
    const layout = { md: 12, lg: 8, xl: 8, xxl: 6 }
    // 表单项children节点
    const formItemChildrenNodes = ctx.children?.map((child) => {
      return <a-col {...{ props: { ...layout } }}>{child}</a-col>
    })

    const handleReset = typeof reset === 'function' ? reset : noop
    const handleSearch = typeof search === 'function' ? search : noop

    // 操作按钮
    const BtnGroup = (
      <a-col class="btn_group_col">
        <a-form-model-item lable="xxx" wrapperCol={{ span: 24 }}>
          <a-button icon="sync" class="mr-10" onClick={handleReset}>
            重置
          </a-button>
          <a-button icon="search" type="primary" onClick={handleSearch}>
            搜索
          </a-button>
        </a-form-model-item>
      </a-col>
    )

    return (
      <a-row class="header_filter_form_wrapper" gutter={20} type="flex" align="middle">
        {formItemChildrenNodes}
        {BtnGroup}
      </a-row>
    )
  }
}
</script>

<style lang="scss" scoped>
.header_filter_form_wrapper {
  ::v-deep .ant-form-item {
    margin-bottom: 8px;
  }
}

// 按钮组始终居右
.btn_group_col {
  width: 300px;
  flex: 1;
  text-align: right;
}
</style>
