import storage from 'store'
import { login, getValidCodeByPhone, getValidCodeByEmail, loginWithSms, loginWithEmail } from '@/api/login'
import { getInfo, getUserAllPermission } from '@/api/currentuser'
import { ACCESS_TOKEN, CURRENT_USER, CURRENT_PERMISSION, ORGANIZATION_ID } from '@/store/mutation-types'
import { getCookie, setCookie } from '@/utils/cookiesUtil'
import router from '@/router'

const user = {
  state: {
    token: '',
    name: '',
    welcome: '',
    avatar: '',
    roles: [],
    permissions: [],
    currentuser: {}
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { name, welcome }) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSION: (state, permissions) => {
      if (permissions && permissions.length > 0) {
        state.permissions = permissions
      } else {
        state.permissions = ['ALWAYS']
      }
    },
    SET_INFO: (state, currentuser) => {
      state.currentuser = currentuser
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          return setCookie('SESSION_ID', response) // 此处需要手动设置
        }).then(() => {
          storage.set(ACCESS_TOKEN, getCookie('SESSION_ID'))
          commit('SET_TOKEN', getCookie('SESSION_ID'))
          resolve(getCookie('SESSION_ID'))
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    async GetInfo({ commit }) {
      var res = await getInfo()
      commit('SET_INFO', res)
      storage.set(CURRENT_USER, res)
    },
    GetValidCodeByPhone({ commit }, payload) {
      return new Promise((resolve, reject) => {
        getValidCodeByPhone(payload).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetValidCodeByEmail({ commit }, payload) {
      return new Promise((resolve, reject) => {
        getValidCodeByEmail(payload).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    LoginWithSms({ commit }, payload) {
      return new Promise((resolve, reject) => {
        loginWithSms(payload).then(response => {
          // if (response.code === 1000) {
          storage.set(ACCESS_TOKEN, getCookie('SESSION_ID'))
          commit('SET_TOKEN', getCookie('SESSION_ID'))
          resolve(getCookie('SESSION_ID'))
          // }
        }).catch(error => {
          reject(error)
        })
      })
    },
    LoginWithEmail({ commit }, payload) {
      return new Promise((resolve, reject) => {
        loginWithEmail(payload).then(response => {
          // if (response.code === 1000) {
          storage.set(ACCESS_TOKEN, getCookie('SESSION_ID'))
          commit('SET_TOKEN', getCookie('SESSION_ID'))
          resolve(getCookie('SESSION_ID'))
          // }
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 登出
    Logout({ commit, state }) {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      commit('SET_ROUTERS', [])
      commit('SET_PERMISSION', [])
      storage.remove(ACCESS_TOKEN)
      storage.remove(CURRENT_USER)
      storage.remove(CURRENT_PERMISSION)

      window.logoutTimer = setTimeout(() => {
        const loginURL = `/user/login?orgId=${storage.get(ORGANIZATION_ID)}`
        router.push(loginURL).then(() => {
          console.log('object')
          window.location.reload()
        })
      }, 100)
    },
    // 获取用户信息
    async GetUserAllPermission({ commit }) {
      var res = await getUserAllPermission()
      // if (res.code === 1000) {
      commit('SET_PERMISSION', res)
      storage.set(CURRENT_PERMISSION, res)
      // }
    }
  }
}

export default user
