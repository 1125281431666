<!--
 * @Description: 地区选择器
-->
<template>
  <a-cascader
    placeholder="请选择"
    v-model="realVal"
    v-bind="$attrs"
    :fieldNames="fieldNames"
    :options="options"
    :load-data="onLoadData"
    @change="onChange"
  />
</template>

<script>
import request from '@/utils/request'
import { flattenTreeToArray, jsonToTree } from '@/utils/arrayUtil'

const fieldNames = { label: 'name', value: 'code', children: 'children' }
// 获取地区数据请求
const getDictrictData = (params) => {
  return request({
    methods: 'get',
    url: '/api/location/district/getDistricts',
    params
  })
}

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    maxLevel: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      fieldNames,
      realVal: [],
      options: []
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this._initData()
      }
    }
  },
  methods: {
    async _initData() {
      if (!this.value) {
        this.realVal = []
        const res = await this.loadTreeData()
        this.options = res
      } else {
        this._initExistVal()
      }
    },
    // 获取已经有默认地址的数据
    async _initExistVal() {
      const params = {
        parentCode: '',
        selectedCode: this.value,
        maxLevel: this.maxLevel
      }
      const Data = await getDictrictData(params)
      // 处理地址数据
      const newFlatternData = this._processResData(flattenTreeToArray(Data))
      this.options = jsonToTree({
        fields: { idKey: 'code', pidKey: 'parentCode' },
        list: newFlatternData
      })

      this.realVal = this._getPathVal(this.value)
    },
    // 处理传入的地址编码，获取一个真正的路径
    _getPathVal(value) {
      const _reg = /^((((\d{2})\d{2})\d{2})\d{3})\d{3}$/
      const [villageCode, townCode, countryCode, cityCode, provinceCode] = _reg.exec(value)
      const _fullPath = [villageCode, townCode, countryCode, cityCode, provinceCode].map((item) => item.padEnd(12, 0))
      return Array.from(new Set(_fullPath)).sort((a, b) => a - b)
    },
    // 加载子节点数据
    async loadTreeData(targetOption) {
      const params = {
        parentCode: targetOption?.code || '',
        maxLevel: this.maxLevel
      }

      const Data = await getDictrictData(params)
      const result = this._processResData(Data) // 处理数据
      return result
    },
    // 加载子节点
    async onLoadData(selectedOptions) {
      // 或得当前地区
      const targetOption = selectedOptions[selectedOptions.length - 1]

      try {
        targetOption.loading = true
        const res = await this.loadTreeData(targetOption)
        if (res.length) {
          targetOption.children = res
        } else {
          targetOption.isLeaf = true
        }
        this.options = [...this.options]
      } catch (error) {
        console.log(error)
      } finally {
        targetOption.loading = false
      }
    },
    onChange(val, selectedOptions) {
      // 返回已选择的地区数据
      this.$emit('input', val[val.length - 1])
      this.$emit('change', selectedOptions)
    },
    // 处理拿到的数据
    _processResData(arr = []) {
      return arr.map((item) => {
        const { code, name, parentCode } = item
        return { code, name, parentCode, isLeaf: item.level >= this.maxLevel }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
