import request from '@/utils/request'
const Api = {
  // resetPasswordWithPhone(payload) {
  //   return request({
  //     method: 'POST',
  //     url: '/api/forget',
  //     data: {
  //       ...payload
  //     }
  //   })
  // },
  resetPasswordWithPhone(payload) {
    return request({
      method: 'put',
      url: '/api/userinfo/resetPasswordWithPhone',
      params: payload,
      data: {
        ...payload,
      },
    })
  },
  resetPasswordWithEmail(payload) {
    return request({
      method: 'PUT',
      url: '/api/userinfo/resetPasswordWithEmail',
      data: {
        ...payload,
      },
    })
  },
  /**
postTemo(payload) {
  return request({
    method: 'POST',
    url: '/api/users',
    data: { ...payload }
  })
}
 */
}
export default Api
