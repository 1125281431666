import Vue from 'vue'
import store from '@/store'

/**
 * Action 权限指令
 * 指令用法：
 *  - 在需要控制 action 级别权限的组件上使用 v-action:[method] , 如下：
 *    <a-button v-action:EDIT_USER.EDIT_USER>删除用户</a-button>
 *     jsx
 *     <Button vAction={'EDIT_USER.EDIT_USER'}>编辑用户</Button>
 *  - 当前用户没有权限时，组件上使用了该指令则会被隐藏
 *
 */
const action = Vue.directive('action', {
  inserted: function (el, binding, vnode) {
    if (binding.value) {
      const permissions = store.getters.permissions
      const permissionCodes = (binding.value || '').split('.')
      let isPass = false
      permissions.forEach(p => {
        isPass = isPass || permissionCodes.includes(p.code)
      })
      if (!isPass) {
        el.parentNode && el.parentNode.removeChild(el) || (el.style.display = 'none')
      }
    }
  }
})

export default action
