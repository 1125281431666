/*
 * @Description: 挂载到全局的组件
 */
import Pagination from './Pagination/index.vue'
import FilterLayout from '@/layouts/FilterLayout.vue'
import DistrictCascader from '@/components/District/DistrictCascader'
import DisctrictSelector from '@/components/DisctrictSelector/index.vue'
import RoleSelector from './RoleSelector/index.vue'
import PriceInput from './PriceInput'
import EnumSelector from './EnumSelector'
import ContentLayout from '../layouts/ContentLayout'

// 要挂载的组件列表
const componentsList = {
  Pagination,
  FilterLayout,
  DistrictCascader,
  DisctrictSelector,
  RoleSelector,
  PriceInput,
  EnumSelector,
  ContentLayout
}

export default {
  install (Vue) {
    Object.keys(componentsList).forEach(key => {
      Vue.component(key, componentsList[key])
    })
  }
}
