<template>
  <div class="pagination_wrapper mt-10">
    <a-pagination
      class="custom_pagination"
      show-quick-jumper
      show-size-changer
      :show-total="total => `共 ${total} 条记录`"
      v-bind="$attrs"
      :total="pagination.total"
      :pageSize.sync="pagination.pageSize"
      :pageSizeOptions="['10', '20', '50', '100']"
      @change="handlePageChange"
      @showSizeChange="handleShowSizeChange"
    />
  </div>
</template>

<script>
export default {
  props: {
    pagination: {
      type: Object,
      default: () => ({
        current: 1,
        pageSize: 10,
        total: 0
      })
    }
  },
  data() {
    return {}
  },
  methods: {
    handlePageChange(current, pageSize) {
      const newPagination = { ...this.pagination, current, pageSize }
      this._updatePagination(newPagination)
    },
    handleShowSizeChange(current, pageSize) {
      const newPagination = { ...this.pagination, current, pageSize }
      this._updatePagination(newPagination)
    },
    _updatePagination(newVal) {
      this.$emit('update:pagination', newVal)
      this.$emit('change', newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
.custom_pagination {
  text-align: right;
}
</style>
