var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.wrpCls },
    [
      _c(
        "a-modal",
        {
          attrs: {
            width: "50%",
            visible: _vm.msgDetShow,
            footer: null,
            title: "消息详细",
          },
          on: { cancel: _vm.msgDetClose },
        },
        [
          _c("h4", [_vm._v(_vm._s(this.selectMsg.title))]),
          _c("div", [
            _vm._v(
              _vm._s(
                this.selectMsg && this.selectMsg.messageTime
                  ? _vm.tranTime(this.selectMsg.messageTime)
                  : ""
              )
            ),
          ]),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px", "margin-bottom": "50px" } },
            [_vm._v(_vm._s(this.selectMsg.detail))]
          ),
        ]
      ),
      _c("span", [_vm._v("机构编码: " + _vm._s(_vm.orgId))]),
      _c("avatar-dropdown", {
        class: _vm.prefixCls,
        attrs: { menu: _vm.showMenu, "current-user": _vm.currentUser },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }