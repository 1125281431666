var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pagination_wrapper mt-10" },
    [
      _c(
        "a-pagination",
        _vm._b(
          {
            staticClass: "custom_pagination",
            attrs: {
              "show-quick-jumper": "",
              "show-size-changer": "",
              "show-total": function (total) {
                return "共 " + total + " 条记录"
              },
              total: _vm.pagination.total,
              pageSize: _vm.pagination.pageSize,
              pageSizeOptions: ["10", "20", "50", "100"],
            },
            on: {
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.pagination, "pageSize", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.pagination, "pageSize", $event)
              },
              change: _vm.handlePageChange,
              showSizeChange: _vm.handleShowSizeChange,
            },
          },
          "a-pagination",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }