export default {
    'menu.welcome': '欢迎',
    'menu.home': '主页',
    'menu.usermem': '用户中心',
    'menu.usermem.user': '用户管理',
    'menu.usermem.roles': '角色管理',
    'menu.exception': '异常页',
    'menu.exception.not-permission': '403',
    'menu.exception.not-find': '404',
    'menu.exception.server-error': '500',
    'menu.exception.trigger': '触发错误',
    'menu.account': '个人页',
    'menu.account.center': '个人中心',
    'menu.account.settings': '个人设置',
    'menu.account.trigger': '触发报错',
    'menu.account.logout': '退出登录'
}
