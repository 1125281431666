const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view'),
}
export default [
  {
    sort: 80,
    path: '/system',
    name: 'system',
    redirect: '/system/account',
    component: RouteView,
    meta: { title: '系统管理', keepAlive: true, icon: 'desktop', permission: ['ORGANIZATION_PERMISSIONS'] },
    children: [
      {
        path: '/system/account',
        name: 'systemAccount',
        component: () => import('@/views/system/account'),
        meta: { title: '用户管理', keepAlive: true, permission: ['ORGANIZATION_USER_MANAGE'] },
      },
      {
        path: '/system/role',
        name: 'systemRole',
        component: () => import('@/views/core/Role'),
        meta: { title: '角色管理', keepAlive: true, permission: ['ORGANIZATION_ROLE'] },
      },
    ],
  },
  {
    sort: 90,
    path: '/settings',
    name: 'settings',
    redirect: '/settings/personalCenter',
    hideChildrenInMenu: true,
    component: RouteView,
    meta: { title: '个人中心', keepAlive: true, icon: 'desktop', permission: ['ALWAYS'] },
    children: [
      {
        path: '/settings/personalCenter',
        name: 'personalCenter',
        component: () => import('@/views/core/PersonalCenter'),
        meta: { title: '个人中心', keepAlive: true, permission: ['ALWAYS'] },
      },
    ],
  },
]
