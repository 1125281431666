/*
 * @Description: 全局filter
 */

// 转换报价，后台存的int, 需要保留小数两位
function transformBidValue(value) {
  const _val = Number(value)
  return isNaN(_val) ? '-' : Number(_val / 100).toFixed(2)
}

export { transformBidValue }
