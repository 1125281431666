import api from './api'
/**
 * core模块中公用数据控制类
 */
export default {
  namespaced: true,
  namespace: 'CoreModule',
  state: {
    allPermissionTree: []
  },
  mutations: {
    SAVE_PREMISSIONTREE: (state, res) => {
      state.allPermissionTree = res
    }
  },
  actions: {
    async getAllPermissionTree({ dispatch, commit }, payload) {
      console.info('getAllPermissionTree')
      const res = await api.getAllPermissionTree(payload)
      commit('SAVE_PREMISSIONTREE', res)
      return res
    }
  }
}
