import api from './api'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import storage from 'store'
import { getCookie } from '@/utils/cookiesUtil'
export default {
  namespaced: true,
  namespace: 'UserCenterModule',
  state: {
    userInfo: {}
  },
  mutations: {
    SAVE_USERINFO: (state, res) => {
      state.userInfo = res
    }
  },
  actions: {
    async verifyEmailCodeByCurrentUser({ dispatch, commit }, payload) {
      const res = await api.verifyEmailCodeByCurrentUser(payload)
      return res
    },
    async sendEmailCodeByCurrentUser({ dispatch, commit }, payload) {
      const res = await api.sendEmailCodeByCurrentUser(payload)
      return res
    },
    async verifySmsCodeByCurrentUser({ dispatch, commit }, payload) {
      const res = await api.verifySmsCodeByCurrentUser(payload)
      return res
    },
    async sendSmsCodeByCurrentUser({ dispatch, commit }, payload) {
      const res = await api.sendSmsCodeByCurrentUser(payload)
      return res
    },
    async changePassword({ dispatch, commit }, payload) {
      const res = await api.changePassword(payload)
      return res
    },
    async getUserInfo({ dispatch, commit }, payload) {
      const res = await api.getUserInfo(payload)
      // if (res.code === 1000) {
      commit('SAVE_USERINFO', res)
      // }
      return res
    },
    async bindNewPhone({ dispatch, commit }, payload) {
      const res = await api.bindNewPhone(payload)
      return res
    },
    async bindNewEmail({ dispatch, commit }, payload) {
      const res = await api.bindNewEmail(payload)
      return res
    },
    async changeUserName({ dispatch, commit }, payload) {
      const res = await api.changeUserName(payload)
      if (res) {
        storage.set(ACCESS_TOKEN, getCookie('SESSION_ID'))
      }
      return res
    },
    async updateUserBaseInfo({ dispatch, commit }, payload) {
      const res = await api.updateUserBaseInfo(payload)
      return res
    }
  }
}
