import api from './api'

export default {
  namespaced: true,
  namespace: 'UserRegisterModule',
  state: {
  },
  mutations: {

  },
  actions: {
    async registerWitSms({ dispatch, commit }, payload) {
      const res = await api.registerWitSms(payload)
      return res
    },
    async registerWitEmail({ dispatch, commit }, payload) {
      const res = await api.registerWitEmail(payload)
      return res
    }
  }
}
