import request from '@/utils/request'
const Api = {
  updateUserRbacInfo(payload) {
    return request({
      method: 'PUT',
      url: '/api/users/updateUserRbacInfo',
      data: { ...payload }
    })
  },
  getAllAuthUsers(payload) {
    return request({
      method: 'POST',
      url: `/api/users/getAllAuthUsers?size=${payload.size}&page=${payload.page}`,
      data: {
        ...payload
      }
    })
  },
  getAllFrontAuthUsers(payload) {
    return request({
      method: 'GET',
      url: '/api/users/getAllFrontAuthUsers',
      params: {
        ...payload
      }
    })
  },
  // 创建用户
  createUser(payload) {
    return request({
      method: 'POST',
      url: '/api/users',
      data: { ...payload }
    })
  },
  // 编辑用户
  updateUser(payload) {
    return request({
      method: 'PUT',
      url: '/api/users',
      data: { ...payload }
    })
  },
  deleteUser(payload) {
    return request({
      method: 'DELETE',
      url: `/api/users/${payload.id}`
    })
  },
  // 重置用户密码
  resetUserPassword(payload) {
    return request({
      method: 'PUT',
      url: `/api/users/resetUserPassword/${payload.id}`
    })
  },
  // 更新用户状态
  changeStatus(payload) {
    return request({
      method: 'PUT',
      url: `api/users/changeStatus/${payload.id}/${payload.activeflg === 'ACTIVE' ? 1 : 0}`
    })
  },
  assignPermissions(payload) {
    return request({
      method: 'POST',
      url: `/api/users/assign-permissions/${payload.id}`,
      data: payload.pids
    })
  },
  assignRole(payload) {
    return request({
      method: 'POST',
      url: `/api/users/assign-role/${payload.id}`,
      data: payload.rids
    })
  },
  addTags(payload) {
    return request({
      method: 'POST',
      url: `/api/users/addTags/${payload.userId}`,
      data: payload.ids
    })
  }
}

export default Api
