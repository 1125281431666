var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    {
      attrs: { value: _vm.value, mode: "multiple", placeholder: "请选择角色" },
      on: { change: _vm.handleChange },
    },
    _vm._l(_vm.options, function (option) {
      return _c("a-select-option", { key: option.value }, [
        _vm._v(_vm._s(option.label)),
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }