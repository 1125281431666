<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import { domTitle, setDocumentTitle } from '@/utils/domUtil'
import { i18nRender } from '@/locales'
import { getCookie } from '@/utils/cookiesUtil'

export default {
  create() {},
  data() {
    return {}
  },
  mounted() {
    this.bindVisibleChangeEvent()
  },
  methods: {
    // 处理只能登录一个检测机构的问题
    bindVisibleChangeEvent() {
      const _this = this
      document.addEventListener('visibilitychange', async function () {
        const cookieToken = getCookie('SESSION_ID')
        const storeToken = _this.$store.state.user.token
        if (cookieToken && storeToken && cookieToken !== storeToken) {
          window.location.reload()
        }
      })
    },
    openNotificationWithIcon(type, title, message) {
      this.$notification[type]({
        message: title,
        description: message
      })
    }
  },
  computed: {
    locale() {
      // 只是为了切换语言时，更新标题
      const { title } = this.$route.meta
      title && setDocumentTitle(`${i18nRender(title)} - ${domTitle}`)

      return this.$i18n.getLocaleMessage(this.$store.getters.lang).antLocale
    }
  }
}
</script>
<style lang="scss">
.ant-layout-footer {
  display: none;
}
</style>
