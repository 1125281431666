/**
 * <a-button v-if="$action('EDIT_USER.EDIT_USER')">Button</a-button>
 * @param Vue
 */
function plugin(Vue) {
  if (plugin.installed) {
    return
  }

  !Vue.prototype.$action && Object.defineProperties(Vue.prototype, {
    $action: {
      get() {
        const _this = this
        return (permissions) => {
          if (permissions) {
            const permission = (permissions || '').split('.')
            const permissionList = _this.$store.getters.permissions
            let isPass = false
            permissionList.forEach(p => {
              isPass = isPass || permission.includes(p.code)
            })
            return isPass
          } else {
            return true
          }
        }
      }
    }
  })
}

export default plugin
