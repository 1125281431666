
// load store state to page
export function loadStoreToData(comp, nameSpace) {
  comp.$store.subscribe(() => {
    Object.keys(comp.$store.state[`${nameSpace}`]).forEach(key => {
      comp[`${key}`] = comp.$store.state[`${nameSpace}`][`${key}`]
    })
  })
  Object.keys(comp.$store.state[`${nameSpace}`]).forEach(key => {
    comp.$root.constructor.util.defineReactive(comp, key, comp.$store.state[`${nameSpace}`][`${key}`])
  })
}
