import request from '@/utils/request'
/**
 * core模块中公用网络请求类
 */
const Api = {
  getAllPermissionTree(payload) {
    return request({
      method: 'GET',
      url: '/api/permission-menus/getAllPermissionTree'
    })
  }
}
export default Api
