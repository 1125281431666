import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import user from './modules/user'

// default router permission control
import permission from './modules/permission'
// dynamic router permission control (Experimental)
// import permission from './modules/async-router'
import getters from './getters'
import createPersistedState from 'vuex-persistedstate'

// 注册 view models
//  eslint-disable-next-line
const files = require.context('../views', true, /\module.js$/)
const modules = {}
files.keys().forEach(key => {
  modules[files(key).default.namespace] = files(key).default
})
Vue.use(Vuex)

export default new Vuex.Store({
  // namespaced: true,
  modules: {
    ...modules,
    app,
    user,
    permission
  },
  state: {

  },
  mutations: {

  },
  actions: {

  },
  getters,
  // 使用vuex-persistedstate把状态保存到localStorage里
  // paths为需要保存的state,格式为module.state
  plugins: [createPersistedState({
    key: 'chilunyc_state',
    paths: ['HolidayModule.holidayInYear']
  })]
})
