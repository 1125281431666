import request from '@/utils/request'
const Api = {
  copyRole(payload) {
    return request({
      method: 'POST',
      url: `api/auth-roles/copyRole/${payload.copiedId}/${payload.roleName}`
    })
  },
  getPermissionByRoles(payload) {
    return request({
      method: 'POST',
      url: '/api/permissions/getPermissionByRoles',
      data: payload
    })
  },
  getRolesByPage(payload) {
    return request({
      method: 'POST',
      url: `/api/auth-roles/getAllAuthRoles?size=${payload.pagination.size}&page=${payload.pagination.page}`,
      data: {
        ...payload.queryVm
      }
    })
  },
  createRoles(payload) {
    return request({
      method: 'POST',
      url: '/api/auth-roles',
      data: {
        ...payload
      }
    })
  },
  updateRoles(payload) {
    return request({
      method: 'PUT',
      url: '/api/auth-roles',
      data: {
        ...payload
      }
    })
  },
  delRoles(payload) {
    return request({
      method: 'DELETE',
      url: `/api/auth-roles/${payload.id}`
    })
  },
  getRole(payload) {
    return request({
      method: 'GET',
      url: `/api/auth-roles/${payload.id}`
    })
  },
  allotPermissions(payload) {
    return request({
      method: 'POST',
      url: `/api/auth-roles/allot-permissions/${payload.id}`,
      data: payload.pids
    })
  },
  // 获取app登录状态
  getDisableAppLogin(payload) {
    return request({
      method: 'GET',
      url: `/api/auth-roles/getDisableAppLogin/${payload.id}`
    })
  },
  // 用户管理-禁用启用app登陆
  disableAppLogin(payload) {
    return request({
      method: 'POST',
      url: `/api/auth-roles/disableAppLogin/${payload.id}`
    })
  }
}
export default Api
