var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content_layout_wrapper" },
    [
      _c("a-card", { staticClass: "filter_area_wrapper" }, [_vm._t("top")], 2),
      _c("div", { staticClass: "mt-10" }),
      _c(
        "a-card",
        { staticClass: "table_area_wrapper" },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }