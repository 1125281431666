const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view'),
}
export default [
  // 项目管理
  {
    sort: 10,
    path: '/project',
    component: RouteView,
    redirect: '/project/list',
    name: 'project',
    meta: { title: '项目管理', icon: 'setting', permission: ['ORGANIZATION_PROJECTS'] },
    children: [
      {
        path: '/project/list',
        name: 'projectList',
        component: () => import('@/views/project/list'),
        meta: { title: '项目列表', permission: ['ORGANIZATION_PROJECTS_CHILD'] },
      },
    ],
  },
]
