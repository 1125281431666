var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    {
      attrs: { allowClear: "", value: _vm.value, placeholder: _vm.placeholder },
      on: { change: _vm.handleChange },
    },
    _vm._l(_vm.enumConfig.values, function (enumVal) {
      return _c(
        "a-select-option",
        { key: enumVal, attrs: { value: enumVal } },
        [_vm._v(_vm._s(_vm.enumConfig[enumVal]))]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }