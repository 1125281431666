<!--
 * @Description: 价格输入器 后端存的int 需要 乘除 100 转换
-->
<template>
  <a-input-number v-bind="$attrs" v-on="$listeners" class="num_input" v-model="_value"></a-input-number>
</template>

<script>
import Big from 'big.js'

export default {
  name: 'PriceInput',
  props: {
    value: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {}
  },
  computed: {
    _value: {
      get() {
        if (this.value == null || this.value === '') return undefined
        return Big(this.value).div(100).toNumber()
      },
      set(newVal) {
        if (newVal <= 0 || isNaN(Number(newVal))) {
          this.$emit('input', undefined)
        } else {
          const _newVal = newVal == null || newVal === '' ? undefined : Big(newVal).times(100).toNumber()
          this.$emit('input', _newVal)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.num_input {
  width: 100%;
}
</style>
