import api from './api'

export default {
  namespaced: true,
  namespace: 'RoleModule',
  state: {
    rolesPage: {},
    role: {}
  },
  mutations: {
    SAVE_ROLES: (state, res) => {
      state.rolesPage = { data: res.content, pagination: { pageSize: res.pageable.pageSize, pageNo: res.pageable.pageNumber + 1, total: res.totalElements } }
    },
    SAVE_ROLE: (state, res) => {
      state.role = res
    },
    SAVE_PERMISSION: (state, res) => {
      if (res && res.length > 0) {
        state.permissionList = res.map(item => {
          return item.id
        })
      } else {
        state.permissionList = []
      }
    }
  },
  actions: {
    async copyRole({ dispatch, commit }, payload) {
      const res = await api.copyRole(payload)
      return res
    },
    async getPermissionByRoles({ dispatch, commit }, payload) {
      console.info('getPermissionByRoles')
      const res = await api.getPermissionByRoles(payload)
      commit('SAVE_PERMISSION', res)
      return res
    },
    async getRolesByPage({ dispatch, commit }, payload) {
      const res = await api.getRolesByPage(payload)
      // if (res.code === 1000) {
      commit('SAVE_ROLES', res)
      // }
      return res
    },
    async createRole({ dispatch, commit }, payload) {
      const res = await api.createRoles(payload)
      return res
    },
    async updateRole({ dispatch, commit }, payload) {
      const res = await api.updateRoles(payload)
      return res
    },
    async delRole({ dispatch, commit }, payload) {
      const res = await api.delRoles(payload)
      return res
    },
    async getRole({ dispatch, commit }, payload) {
      const res = await api.getRole(payload)
      // if (res.code === 1000) {
      commit('SAVE_ROLE', res)
      // }
      return res
    },
    async allotPermissions({ dispatch, commit }, payload) {
      const res = await api.allotPermissions(payload)
      return res
    }
  }
}
