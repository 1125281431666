var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pro-layout",
    _vm._b(
      {
        attrs: {
          menus: _vm.menus,
          collapsed: _vm.collapsed,
          mediaQuery: _vm.query,
          isMobile: _vm.isMobile,
          handleMediaQuery: _vm.handleMediaQuery,
          handleCollapse: _vm.handleCollapse,
          i18nRender: _vm.i18nRender,
        },
        scopedSlots: _vm._u([
          {
            key: "menuHeaderRender",
            fn: function () {
              return [
                _c(
                  "div",
                  [_c("logo-svg"), _c("h1", [_vm._v(_vm._s(_vm.title))])],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "headerContentRender",
            fn: function () {
              return [
                _c(
                  "div",
                  [
                    _c(
                      "a-tooltip",
                      { attrs: { title: "刷新页面" } },
                      [
                        _c("a-icon", {
                          staticStyle: {
                            "font-size": "18px",
                            cursor: "pointer",
                          },
                          attrs: { type: "reload" },
                          on: { click: _vm.reload },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "rightContentRender",
            fn: function () {
              return [
                _c("right-content", {
                  attrs: {
                    "top-menu": _vm.settings.layout === "topmenu",
                    "is-mobile": _vm.isMobile,
                    theme: _vm.settings.theme,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      },
      "pro-layout",
      _vm.settings,
      false
    ),
    [_c("router-view")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }