// with polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import i18n from './locales'
import { VueAxios } from './utils/request'
import ProLayout, { PageHeaderWrapper } from '@ant-design-vue/pro-layout'
import themePluginConfig from '../config/themePluginConfig'
import './styles/index.scss' // 引入sass
// import 'vxe-table/lib/style.css' // 引入vxe.table样式
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import auth from '@/directive/auth/index' // 挂载自定义权限指令

// mock
// WARNING: `mockjs` NOT SUPPORT `IE` PLEASE DO NOT USE IN `production` ENV.
// import './mock'

import bootstrap from './core/bootstrap'
import './core/lazy_use' // use lazy load components
import './permission' // permission control
import './utils/filter' // global filter
import './global.less' // global style
import { loadStoreToData } from '@/utils/storeutils'
import notification from 'ant-design-vue/es/notification'
import validator from '@/utils/validator'
import { sequentialSetData } from '@/utils/util'
import databus from '@/utils/util.databus'
import configApp from './configApp'
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import { formatTime } from '@/utils/date'

VXETable.setup({
  table: {
    border: 'inner',
    size: 'small',
    autoResize: true
  }
})

// 枚举值formater
VXETable.formats.add('enum', ({ cellValue }, enumConfig = {}) => {
  return enumConfig[cellValue] || '-'
})
// 时间formater
VXETable.formats.add('date', ({ cellValue }, formatStr = 'YYYY-MM-DD') => {
  return formatTime(cellValue, formatStr)
})

VXETable.formats.add('price', ({ cellValue }) => {
  const _val = Number(cellValue / 100)
  return isNaN(_val) ? '-' : _val.toFixed(2)
})

Vue.use(VXETable)
Vue.use(VueViewer)
// import { connect } from '@/utils/socket'
// connect()
Vue.config.productionTip = false

// mount axios to `Vue.$http` and `this.$http`
Vue.use(VueAxios)
// use pro-layout components
Vue.component('pro-layout', ProLayout)
Vue.component('page-container', PageHeaderWrapper)
Vue.component('page-header-wrapper', PageHeaderWrapper)
// 自定义的配置方法
configApp.init(Vue)

window.umi_plugin_ant_themeVar = themePluginConfig.theme
Vue.use(auth) // 挂载权限指令
const $vue = new Vue({
  router,
  store,
  i18n,
  // init localstorage, vuex
  created: bootstrap,
  render: h => h(App)
}).$mount('#app')
Vue.prototype.$loadStoreToData = loadStoreToData
Vue.prototype.$seqlSetData = sequentialSetData
Vue.prototype.$validator = validator
Vue.prototype.$databus = databus
window.$vue = $vue
window.eventBus = new Vue()
window.eventBus.$on('error', error => {
  notification.error({
    message: error.title,
    description: error.msg
  })
})
