<!--
 * @Description: 文件描述
-->
<template>
  <a-select allowClear :value="value" :placeholder="placeholder" @change="handleChange">
    <a-select-option
      v-for="enumVal in enumConfig.values"
      :key="enumVal"
      :value="enumVal"
    >{{ enumConfig[enumVal] }}</a-select-option>
  </a-select>
</template>

<script>
export default {
  name: 'EnumSelector',
  props: {
    enumConfig: {
      type: Object,
      default: () => ({ values: [] })
    },
    value: {
      type: [Number, String],
      default: undefined
    },
    placeholder: {
      type: String,
      default: '全部'
    }
  },
  data() {
    return {}
  },
  methods: {
    handleChange(newVal) {
      this.$emit('input', newVal)
      this.$emit('change', newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
