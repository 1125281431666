var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-cascader",
    _vm._b(
      {
        attrs: {
          placeholder: "请选择",
          fieldNames: _vm.fieldNames,
          options: _vm.options,
          "load-data": _vm.onLoadData,
        },
        on: { change: _vm.onChange },
        model: {
          value: _vm.realVal,
          callback: function ($$v) {
            _vm.realVal = $$v
          },
          expression: "realVal",
        },
      },
      "a-cascader",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }