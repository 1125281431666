import api from './api'

export default {
  namespaced: true,
  namespace: 'RetrievePasswordModule',
  state: {
  },
  mutations: {

  },
  actions: {
    async resetPasswordWithPhone({ dispatch, commit }, payload) {
      const res = await api.resetPasswordWithPhone(payload)
      return res
    },
    async resetPasswordWithEmail({ dispatch, commit }, payload) {
      const res = await api.resetPasswordWithEmail(payload)
      return res
    }
  }
}
