import _ from 'lodash'

/**
 * @description: 将树结构(默认子节点的字段为children)变为一维
 * @param {Array} 树状结构的数组
 * @return {Array} 一维数组
 */
 function flattenTreeToArray(treeData, childrenKey = 'children') {
  // 递归方法
  const reduceFunc = (pre = [], cur) => {
    // 如果元素有children，那么要启动递归
    if (Array.isArray(cur[childrenKey])) {
      return pre.concat(cur).concat(flattenTreeToArray(cur[childrenKey]))
    } else {
      return pre.concat(cur)
    }
  }

  return treeData.reduce(reduceFunc, [])
}

/**
 * @description: // 循环的方式转一维数组为树，效率最高
 * @param list {Array} 要装换的一维数组，必填
 * @param fields {Object} 组树的默认字段， id, parentId, chidlren 已经赋予默认值，通常不需要填
 * @param rootId {guid} 根节点id, 默认是0000-0000...
 * @param extraProperty {Object} 需要添加的额外参数
 * @return {type} Array 树形数组
 */
 export function jsonToTree(param = {}) {
  const { fields = {}, extraProperty = {} } = param
  let { list = [] } = param

  if (Array.isArray(param)) { // 兼容一下数组写法
    list = param
  }

  const {
    idKey = 'id', pidKey = 'parentId', childrenKey = 'children'
  } = fields // 默认的根节点id
  const jsonData = _.cloneDeep(list)
  const result = []
  const temp = {}

  for (let i = 0; i < jsonData.length; i++) {
    temp[jsonData[i][idKey]] = jsonData[i] // 以id作为索引存储元素，可以无需遍历直接定位元素
  }
  for (let j = 0; j < jsonData.length; j++) {
    const currentElement = jsonData[j]
    const tempCurrentElementParent = temp[currentElement[pidKey]] // 临时变量里面的当前元素的父元素
    if (tempCurrentElementParent) {
      // 如果存在父元素
      if (!tempCurrentElementParent[childrenKey]) {
        // 如果父元素没有chindren键
        tempCurrentElementParent[childrenKey] = [] // 设上父元素children键
      }
      // 天坑啊。 千万别这么写。。。。。。吃亏吃死了，此写法会改变引用对象的引用地址，这个组树方法里面最关键的一点就是引用地址不变！
      // currentElement = { ...currentElement, ...extraProperty }
      Object.assign(currentElement, extraProperty)
      tempCurrentElementParent[childrenKey].push(currentElement) // 给父元素加上当前元素作为子元素
    } else {
      // 不存在父元素，意味着当前元素是一级元素
      result.push(currentElement)
    }
  }
  return result
}

export { flattenTreeToArray }

export default {
  /**
* 数组元素交换位置
* @param {array} arr 数组
* @param {number} index1 添加项目的位置
* @param {number} index2 删除项目的位置
* index1和index2分别是两个数组的索引值，即是两个要交换元素位置的索引值，如1，5就是数组中下标为1和5的两个元素交换位置
*/
  swapArray: function (arr, index1, index2) {
    arr[index1] = arr.splice(index2, 1, arr[index1])[0]
    return arr
  },
  // 移动位置，length<0下移,length>0上移
  zIndex: function (arr, index, length) {
    if (length < 0) {
      for (var i = 0; i < length * -1; i++) {
        this.zIndexDown(arr, index - i, arr.length)
      }
    } else {
      for (var j = 0; j < length; j++) {
        this.zIndexUp(arr, index + j, arr.length)
      }
    }
  },
  // 上移 将当前数组index索引与后面一个元素互换位置，向数组后面移动一位
  zIndexUp: function (arr, index, length) {
    if (index + 1 !== length) {
      this.swapArray(arr, index, index + 1)
    } else {
      alert('已经处于置顶，无法上移')
    }
  },

  // 下移 将当前数组index索引与前面一个元素互换位置，向数组前面移动一位
  zIndexDown: function (arr, index, length) {
    if (index !== 0) {
      this.swapArray(arr, index, index - 1)
    } else {
      alert('已经处于置底，无法下移')
    }
  },

  // 置顶，即将当前元素移到数组的最后一位
  zIndexTop: function (arr, index, length) {
    if (index + 1 !== length) {
      // 首先判断当前元素需要上移几个位置,置底移动到数组的第一位
      var moveNum = length - 1 - index
      // 循环出需要一个一个上移的次数
      for (var i = 0; i < moveNum; i++) {
        this.swapArray(arr, index, index + 1)
        index++
      }
    } else {
      console.info('已经处于置顶')
    }
  },

  // 置底，即将当前元素移到数组的第一位
  zIndexBottom: function (arr, index, length) {
    if (index !== 0) {
      // 首先判断当前元素需要上移几个位置,置底移动到数组的第一位
      var moveNum = index - 0
      // 循环出需要一个一个上移的次数
      for (var i = 0; i < moveNum; i++) {
        this.swapArray(arr, index, index - 1)
        index--
      }
    } else {
      console.info('已经处于置底')
    }
  }
}
