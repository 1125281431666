/*
 * @Description: 配置App
 * @important: 样式文件请直接在main.js引入
 */

// import 'xe-utils' // 引入vxe-table-utils
import componentsPlugins from '@/components/plugins.js' // 全局组件
import Vue from 'vue'
import * as filters from './filters' // 注册全局f过滤器
// import auth from '@/directive/auth' // 挂载自定义权限指令
// import moment from 'moment' // 引入moment
// import VXETable from 'vxe-table' // 引入vxe-table
// import { VXE_TABLE_CONFIG } from '../config/vxe-table'

class AppConfig {
  static init() {
    AppConfig.vueConfig()
    // AppConfig.useVxeTable()
    // AppConfig.useVueEchart(Vue)
  }

  // vue自身的全局配置，fitler, directtive, component
  static vueConfig() {
    // 全局filter
    Object.keys(filters).forEach(key => {
      Vue.filter(key, filters[key])
    })
    Vue.use(componentsPlugins) // 全局组件
  }

  // // 使用VXE-table
  // static useVxeTable(Vue) {
  //   // 设置vxe-table默认设置
  //   // VXETable.setup(VXE_TABLE_CONFIG)
  //   // 给 vue 实例挂载全局窗口对象，属性名称随意定义，例如：$XModal
  //   Vue.prototype.$modal = VXETable.modal
  //   // VXE表格
  //   Vue.use(VXETable)
  //   // 添加格式化时间formatter
  //   VXETable.formats.add('date', ({ cellValue }, formatStr = 'YYYY-MM-DD') => {
  //     return cellValue ? moment(cellValue).format(formatStr) : '-'
  //   })
  // }

  // // 使用vue-chart
  // static useVueEchart(Vue) {
  //   // 全局注册组件（也可以使用局部注册）
  //   Vue.component('v-chart', ECharts)
  // }
}

export default AppConfig
