<template>
  <a-dropdown v-if="currentUser && currentUser.username" placement="bottomRight">
    <span class="ant-pro-account-avatar">
      <a-avatar size="small" :src="avatar" class="antd-pro-global-header-index-avatar" />
      <span>{{ currentUser.username }}</span>
    </span>
    <template v-slot:overlay>
      <a-menu class="ant-pro-drop-down menu" :selected-keys="[]">
        <a-menu-item v-if="menu" key="settings" @click="handleToSettings">
          <a-icon type="setting" />
          {{ $t('menu.account.settings') }}
        </a-menu-item>
        <a-menu-divider v-if="menu" />
        <a-menu-item key="logout" @click="handleLogout">
          <a-icon type="logout" />
          {{ $t('menu.account.logout') }}
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
  <span v-else>
    <a-spin size="small" :style="{ marginLeft: 8, marginRight: 8 }" />
  </span>
</template>

<script>
import { Modal } from 'ant-design-vue'
// import { disconnect, connectAndSubscribeSocket } from '@/utils/socket'
export default {
  name: 'AvatarDropdown',
  props: {
    currentUser: {
      type: Object,
      default: () => null
    },
    menu: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    // disconnect()
    // setTimeout(() => {
    //   connectAndSubscribeSocket([
    //     {
    //       url: '/queue/' + this.$props.currentUser.username,
    //       callBack: (body) => {
    //         window.$vue.$store.dispatch('TaskAndMsg/saveTaskObj', body)
    //       }
    //     },
    //     {
    //       url: '/message/' + this.$props.currentUser.username,
    //       callBack: (body) => {
    //         window.$vue.$store.dispatch('TaskAndMsg/saveMessageObj', body)
    //       }
    //     }]
    //   )
    // }, 0)
  },
  computed: {
    avatar() {
      return this.$store.state.UserCenterModule?.userInfo?.avatar || this.$store.state.user?.currentuser?.avatar
    }
  },
  methods: {
    handleToSettings() {
      this.$router.push({ path: '/settings' })
    },
    handleLogout(e) {
      Modal.confirm({
        title: this.$t('layouts.usermenu.dialog.title'),
        content: this.$t('layouts.usermenu.dialog.content'),
        onOk: () => {
          return this.$store.dispatch('Logout').then(() => {
            this.$router.push({ name: 'login' })
          })
        },
        onCancel() {}
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ant-pro-drop-down {
  /deep/ .action {
    margin-right: 8px;
  }
  /deep/ .ant-dropdown-menu-item {
    min-width: 160px;
  }
}
</style>
