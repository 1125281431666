import { asyncRouterMap, constantRouterMap } from '@/config/router.config'
import { getPermissionMenus, getUserAllPermission } from '@/api/currentuser'
/**
 * 过滤账户是否拥有某一个权限，并将菜单从加载列表移除
 *
 * @param permission
 * @param route
 * @returns {boolean}
 */
function hasPermission(permission, route) {
  if (route.meta && route.name) {
    if (route.meta.permission.includes('ALWAYS')) {
      return true
    }
    let flag = false
    for (let i = 0, len = permission.length; i < len; i++) {
      flag = route.meta.permission.includes(permission[i].code)
      if (flag) {
        return true
      }
    }
    return false
  }
  return true
}

/**
 * 单账户多角色时，使用该方法可过滤角色不存在的菜单
 *
 * @param roles
 * @param route
 * @returns {*}
 */
// eslint-disable-next-line
function hasRole(roles, route) {
  if (route.meta && route.meta.roles) {
    return route.meta.roles.includes(roles.id)
  } else {
    return true
  }
}

function filterAsyncRouter(routerMap, roles) {
  const accessedRouters = routerMap.filter(route => {
    if (hasPermission(roles, route)) {
      if (route.children && route.children.length > 0) {
        route.children = filterAsyncRouter(route.children, roles)
      }
      return true
    }
    return false
  })
  return accessedRouters
}

const permission = {
  state: {
    routers: constantRouterMap,
    addRouters: [],
    btnAuthCodeList: [], // 按钮级权限列表
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers
      state.routers = constantRouterMap.concat(routers)
    },
    SET_BUTTON_AUTH_LIST: (state, payload) => {
      state.btnAuthCodeList = payload
    }
  },
  actions: {
    GenerateRoutes({ commit }, data) {
      return new Promise(resolve => {
        const { roles } = data
        const accessedRouters = filterAsyncRouter(asyncRouterMap, roles)
        // const accessedRouters = asyncRouterMap
        if (roles && roles.length > 0) {
          commit('SET_ROLES', roles)
        } else {
          commit('SET_ROLES', [{ code: 'ALWAYS' }])
        }
        commit('SET_ROUTERS', accessedRouters)
        resolve()
      })
    },
    GerPermissionMenus({ commit }, data) {
      return new Promise((resolve, reject) => {
        getPermissionMenus().then(response => {
          resolve(response)
        }
        ).catch(
          error => {
            reject(error)
          })
      }
      )
      // getPermissionMenus()
    },
    async fetchBtnAuthCodeList({ commit }) {
      try {
        const data = await getUserAllPermission() || []
        const authCodeList = data.map(item => item.code)
        commit('SET_BUTTON_AUTH_LIST', authCodeList)
      } catch (error) {
        console.log(error)
      }
    }
  }
}

export default permission
