import request from '@/utils/request'

const api = {
  UserInfo: '/api/userinfo',
  getPermissionMenus: '/api/userinfo/getPermissionMenus',
  getUserAllPermission: '/api/userinfo/getUserAllPermission'
}

export function getInfo() {
  return request({
    url: api.UserInfo,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function getPermissionMenus() {
  return request({
    url: api.getPermissionMenus,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function getUserAllPermission() {
  return request({
    url: api.getUserAllPermission,
    method: 'get'
  })
}

// 修改当前用户信息
export function editUserInfo(data) {
  return request({
    url: '/api/organization/updateInfo',
    method: 'put',
    data
  })
}

// 修改用户密码
export function updateUserInfo(data) {
  return request({
    url: '/api/userinfo/updateUserInfo',
    method: 'put',
    data
  })
}

// 重置用户密码信息
export function resetUserPassword(id) {
  return request({
    url: `/api/users/resetUserPassword/${id}`,
    method: 'put'
  })
}
