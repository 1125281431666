<template>
  <div :class="wrpCls">
    <a-modal width="50%" :visible="msgDetShow" :footer="null" title="消息详细" @cancel="msgDetClose">
      <h4>{{ this.selectMsg.title }}</h4>
      <div>{{ this.selectMsg && this.selectMsg.messageTime ? tranTime(this.selectMsg.messageTime) : '' }}</div>
      <div style="margin-top: 20px; margin-bottom: 50px ">{{ this.selectMsg.detail }}</div>
    </a-modal>
    <span>机构编码: {{ orgId }}</span>
    <avatar-dropdown :menu="showMenu" :current-user="currentUser" :class="prefixCls" />
  </div>
</template>

<script>
import AvatarDropdown from './AvatarDropdown'
import storage from 'store'
import { CURRENT_USER, ORGANIZATION_ID } from '@/store/mutation-types'
// eslint-disable-next-line
import { tranTime } from '@/utils/util'

export default {
  name: 'RightContent',
  components: {
    AvatarDropdown
  },
  created() {
    this.orgId = storage.get(ORGANIZATION_ID)
  },
  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro-global-header-index-action'
    },
    isMobile: {
      type: Boolean,
      default: () => false
    },
    topMenu: {
      type: Boolean,
      required: true
    },
    theme: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tranTime: tranTime,
      taskObj: {},
      messageObj: {},
      selectMsg: {},
      msgDetShow: false,
      dy: '',
      visible: false,
      mvisible: false,
      showMenu: true,
      currentUser: {},
      orgId: ''
    }
  },
  methods: {
    async msgDetClose() {
      await this.$store.dispatch('TaskAndMsg/readMsg', this.selectMsg)
      await this.$store.dispatch('TaskAndMsg/queryMessageList')
      this.msgDetShow = false
      this.selectMsg = {}
    },
    bellClick() {
      this.$store.dispatch('TaskAndMsg/queryMessageList')
      this.mvisible = !this.mvisible
      if (this.visible) {
        this.visible = !this.visible
      }
    },
    async loadData() {
      if (!storage.get(CURRENT_USER)) {
        await this.$store.dispatch('GetInfo')
        this.currentUser = storage.get(CURRENT_USER)
      } else {
        this.currentUser = storage.get(CURRENT_USER)
      }
    }
  },
  computed: {
    wrpCls() {
      return {
        'ant-pro-global-header-index-right': true,
        [`ant-pro-global-header-index-${this.isMobile || !this.topMenu ? 'light' : this.theme}`]: true
      }
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>
